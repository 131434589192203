import React from 'react';

// Path to the logo file
import pipsqueakLogo from '/src/assets/Logo-Pipsqueak-Bookplate-Red_Text-bgTrans-400x380.png';

const PipLogo = () => (
	<a href="https://pipsqueak.com/" target="Pipsqueak">
		<img src={pipsqueakLogo} alt="Pipsqueak logo" style={{ width: 180 }} />
	</a>
);

export default PipLogo;
